import React from 'react'
import Layout from '../components/layout'
import Banner from "../components/BannerImage";
import infBanner from "../assets/images/informatics-banner.jpg";
import infBanner2 from "../assets/images/informatics-banner2.jpg";
import infExample from "../assets/images/informatics_example.png";
import infIpad from "../assets/images/informaticsipad.png";
import theme from "../assets/images/themes-example.png";

const Informatics = props => (
    <Layout pageTitle="Informatics" metaDescription="Informatics" metaKeywords={['informatics', 'data']}>
        <Banner img={infBanner}/>
            <div id="main" >
                <div className="inner">
                    <h2 id="content">Introducing Informatics</h2>
                    <p>  Our Informatics system, offers the intelligence to suit your operational requirements. From Friends and Family Test metrics, though to analysing your qualitative data utilising sentiment analysis.
                    The system enables you to share data securely with stakeholders, in addition to having a clear way of triangulating feedback from multiple sources and interfacing with other systems such as a CRM.
                    Whether understanding patient experience data, staff surveys, tenant insights or the viability of your next product. Informatics helps you to measure satisfaction and understand experiences.</p>
                </div>
            </div>
            <div id="main" className="alt">
                <div className="inner">
                    <h2 id="content">Why Informatics?</h2>
                    <section id="one">
                        <div className="grid-wrapper">
                            <div className="col-4">
                                <h3>Advanced filtering to enable you to drill down to those key talking points.</h3>
                                <p>You can quickly identify and compare your key data sources, look at the themes and sub-themes that you have collected from the Feedback Centre, and separate them by category, provider or group. Add to this customisable date filtering to compare themes and sub themes by specific time periods, and the trends and issues are clearly identified.</p>
                                <span className="image fit"><img src={theme} alt="Kettering General Hospital Feedback Centre Platform" /></span>
                            </div>
                            <div className="col-4">
                                <h3>A dashboard so easy to use that you'll confidently allow your partners to have access to it. </h3>
                                <p>With an easy to navigate main screen that automatically refreshes as you select the views you require, Informatics makes reports easy to run at any time of day, at the click of a button. Downloadable graphs help you build external reports in whatever format you work with, and you can always provide health providers secure access to their own data, which has been widely adopted.</p>
                               <img src={infIpad} alt="Kettering General Hospital Feedback Centre Platform" height="500"/>
                            </div>
                            <div className="col-4">
                              <h3>Fully customisable to suit your reporting needs.</h3>
                              <p>Our dedicated team will work alongside you to gather your sources, themes, service providers and set up any extra users that may need access. We're here to help you take the action you need to delight your customers, engage your staff and share data organisation-wide.  </p>
                                <span className="image fit"><img src={infExample} alt="Kettering General Hospital Feedback Centre Platform" /></span>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        <Banner img={infBanner2}/>
    </Layout>
)

export default Informatics